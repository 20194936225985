import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    textAlign: 'center',
    width: '30%',
    height: '262.17',
    display: 'flex',
    maxWidth: 'none',
    '& p': {

      width: '100% !important',

    },
  },
  griplist: {
    backgroundColor: 'white',
    border: '1px solid #4363',
    justifyContent:'center',
    borderRadius: 15,
    marginTop: '25%',
    margin: '5%',
    position: 'relative',
    zIndex: 2,
    boxShadow: '4px 4px 60px 0px rgba(76, 29, 149, 0.30)',
    padding: '80px 30px 5px',
    [theme.breakpoints.only('xs')]: {
      margin: 5
    },
  },
  paper: {
    height: '100%',
  },
  title: {
    fontSize: 34,
  },
  subtitle: {
    fontSize: 20,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  typographySubtitle:{
    color: '#000',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.2px',
    marginTop:'5%',
  },
  typographyTitle:{
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 510,
    lineHeight: '26px',
    letterSpacing: '0.5px',
  },
  containerSpace: {
    [theme.breakpoints.down('sm')]: {
    },
    height: '100%'
  },
  backgroundOverview: {
    position: 'absolute',
    width: '100%',
    height: 100,
    backgroundColor: '#FFF',
    top: 0,
    left: 0,
    zIndex: 2,
    borderRadius: '25px',
  },
  overview: {
    zIndex: 1,
    width: 123,
    height: 123,
    borderRadius: 120,
    borderColor: 'white',
    borderStyle: 'solid',
    borderWidth: 'thick',
    position: 'absolute',
    boxShadow: '0px 0px 10px 10px #ede9f4',
    left: '50%',
    top: '-11%',
    transform: 'translate(-50%, -20%)',
    overflow: 'hidden',
  },
  imageCircle: {
    zIndex: 3,
    position: 'absolute',
    width: 114,
    height: 114,
    borderRadius: 150,
    left: '50%',
    top: '-10%',
    transform: 'translate(-50%, -20%)',
  },
  maskContainer: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
  },
  mask: {
    width: '100%',
    zIndex: 10,
    borderRadius: 11,
  },
  scrollContainer: {
    zIndex: 2,
    overflow: 'hidden',
    maxHeight: 400,
    width: '95%',
    position: 'absolute',
    top: 50,
    left: 20,
    willChange: 'transform',
    transformStyle: 'preserve-3d',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      left: 10,
      top: 60,
      maxHeight: 300,
    },
    [theme.breakpoints.only('sm')]: {
      left: 17,
      maxHeight: 300,
    },
    [theme.breakpoints.only('xs')]: {
      top: 30,
      left: 6,
      overflow: 'hidden',
      maxHeight: 140,
    },
  },
  content1: {
    width: '100%',
  },
  paperContainer: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
}));

export default styles;