import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(10),
    textAlign: 'center',
  },
  video: {
    width: '100%',
    maxHeight: 400,
    marginBottom: 20,
  },
  btn: {
    backgroundColor: 'rgba(64,140,255,.1)',
    boxShadow: 'none',
    width: 160,
    height: 50,
    borderRadius: 34,
    marginTop: 20,
    '& p': {
      color: '#4058ff',
    },
    '&:hover': {
      backgroundColor: 'rgba(64,140,255,.2)',
      boxShadow: 'none',
    },
  },
}));

export default styles;
