import React from 'react';
import { Container, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import styles from './styles';

const Why3 = () => {
  const { t } = useTranslation();
  const classes = styles();

  const open = () => window.open('https://app.getbiew.com/', '_blank');

  return (
    <Container className={classes.root} id="features">
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.container}>
          <img
            alt="img"
            src="/assets/img/why3Photo1.png"
            className={classes.image}
            width="100%"
          />
        </Grid>

        <Grid container className={classes.purple} item lg={6} md={6} sm={12} xs={12}>
          <Typography className={clsx(classes.typographyTitle,classes.widthContainer1)}>{t('why3.title')}</Typography>
          <Typography className={clsx(classes.typographySubtitle,classes.widthContainer2)}>{t('why3.subtitle')}</Typography>
          <div>
            <Button
              variant="contained"
              id="btnStart"
              className={clsx(classes.btn, classes.btnStart)}
              onClick={open}>
              <Typography className={classes.typographyBtn}>{t('nav.started')}</Typography>
            </Button>
          </div>

        </Grid>

      </Grid>
    </Container>
  );
}

export default Why3;