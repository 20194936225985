import React from 'react';
import { Container, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '../../components/Typography';
import styles from './styles';

const Header = () => {
  const { t } = useTranslation();
  const classes = styles();

  const open = () => window.open('https://app.getbiew.com/', '_blank');

  return (
    <Container className={clsx(classes.root,classes.heightBody1)}>
      <Grid container spacing={4} className={classes.marginContainerTop}>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.container}>
        <div className={classes.centerdiv} >
        <Typography className={classes.typographyTitle}>{t('header.title')}</Typography>
        </div>
        <div className={classes.centerdiv} >
        <Typography className={clsx(classes.typographySubtitle,classes.spaceVerticalMiddle,classes.widthSmall)}>{t('header.subtitle')}</Typography>
        </div>
          
          <div className={classes.centerdiv} >
            <Button variant="contained" className={classes.btnStart} onClick={open}>
              <Typography className={classes.typographySubtitle} >{t('nav.started')}</Typography>
            </Button>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.imgContainer}>
            <img
              alt="img"
              src="/assets/img/principal.png"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
