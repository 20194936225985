import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontSize: 14,
  },
  overrides: {
    MuiPaper: {
      root: {
        top:35,
        borderRadius: '16px !important',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;