import React from 'react';
import { Container, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Nav from '../../components/Nav';
import Footer from '../../sections/Footer';
import Typography from '../../components/Typography';
import styles from './styles';

const NotFound = () => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <main>
      <Nav />
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <video className={classes.video} playsInline autoPlay muted loop id="video">
              <source
                src="https://assets.website-files.com/5e8918fccce5fb7307ac1c9e/609ac56b9f36234226d7fcdb_improved-transcode.mp4"
                type="video/mp4"
              />
            </video>
          </Grid>
          <Grid item xs={12}>
            <Typography bold variant="h4">{t('notfound.title')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t('notfound.subtitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Button variant="contained" className={classes.btn}>
                <Typography bold>
                  {t('notfound.button')}
                </Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </main>
  );
};

export default NotFound;

