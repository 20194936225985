import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    color: '#121722',
  },
  bold: {
    fontFamily: 'Roboto !important',
  },
}));

export default styles;
