import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
      '& img': {
        transform: 'scale(1.1)',
      },
      '& .name': {
        color: '#4058FF',
        opacity: 0.8,
      },
    },
  },
  img: {
    width: '100%',
    borderRadius: 16,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.sta
    }),
  },
  name: {
    opacity: 0.45,
    fontSize: 13,
  },
  description: {
    fontSize: 20,
    height: 80,
  }
}));

export default styles;
