import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    maxWidth: 'none',
    marginTop:'5%',
    backgroundColor:'#f2e9fd',
    alignItems:'center',
    paddingLeft:'inherit',
    paddingRight:'inherit',
  },
  container:{
    padding:'2%',
  },
  paper: {
    backgroundColor: '#4058ff',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: theme.spacing(5),
    fontSize: 56,
    color: '#FFF',
    textAlign: 'center',
    alignItems:'center !important',
    display:'grid !important',
    justifyContent:'center !important',
    [theme.breakpoints.only('xs')]: {
      fontSize: 40,
    }
  },
  subtitle: {
    marginTop: theme.spacing(3),
    fontSize: 18,
    color: '#FFF',
    alignItems:'center',
    display:'grid !important',
    justifyContent:'center !important',
  },
  typographySubtitle:{
    color: 'var(--White, #FFF)',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    marginLeft:'5%',
    marginRight:'5%',
  },
  typographyTitle:{
    color: 'var(--White, #FFF)',
    textAlign: 'center',
    fontSize: '56px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '110%', 
    marginLeft:'5%',
    marginRight:'5%',
    marginTop:'5%',
  },
  typographyBtn:{
    color: 'var(--White, #FFF)',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  btn: {
    backgroundColor: '#FFF',
    borderRadius: 23,
    width: 157,
    color: '#4058FF',
    fontSize: 18,
    boxShadow: '0 4px 4px 0 rgb(18 23 34 / 1%), 0 1px 1px 0 rgb(18 23 34 / 17%), inset 0 0 0 0.5px rgb(255 255 255 / 51%)',
    textShadow: '0 -1px 0 rgb(255 255 255 / 16%)',
    marginTop: 20,
  },
  btnContainer: {
    textAlign: 'right',
  },
  widthContainer1:{
    width:'80%',
    marginTop: '10%',
  },
  widthContainer2:{
    width:'90%',
  },
  btnStart: {
    backgroundColor: '#7C39ED !important',
    textTransform:'none',
    boxShadow: 'none !important',
    width: '130%',
    marginBottom:'10%',
    height:64,
    '& p': {
      color: '#FEFEFE',
    },
    '&.scroll': {
      backgroundColor: '#7C39ED !important',
      '& p': {
        color: '#FFF',
      }
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    }

  },
  purple:{
    backgroundColor:'#4c1d95',
    justifyContent:'center'
  },
  centerdiv: {
    marginTop: theme.spacing(2),
    display:'grid !important',
    justifyContent:'center !important',
  },
  textBeautifier:{
    width: '79% !important',
    display: 'inline-table',
    textAlign: 'center',
  }
}));

export default styles;
