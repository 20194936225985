import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(10)
  },
  h2: {
    fontSize: 40,
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 18,
  },
  description: {
    marginBottom: theme.spacing(2)
  },
  nav: {
    padding: theme.spacing(1, 2),
  },
}));

export default styles;
