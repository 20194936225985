import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    backgroundImage: "url('/assets/img/Ellipses.svg')",
    backgroundRepeat:'no-repeat',
    backgroundSize:'300px 300px',
    backgroundPositionX:-65,
    backgroundPositionY:0,
    maxWidth:'none',
    [theme.breakpoints.only('xs')]: {
      backgroundSize:'350px 350px',
      backgroundPositionX:-80,
      backgroundPositionY:150,
    } 
  },
  logo: {
    width: 114,
  },
  copyright: {
    fontSize: 12,
    color: 'rgba(18,23,34,.7)',
  },
  nav: {
    listStyle: 'none',
    '& li': {
      margin: theme.spacing(1, 0),
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        '& p': {
          color: '#4058ff',
        },
      },
    },
    [theme.breakpoints.only('xs')]: {
      width: 170,
    },
  },
  container: {
    display: 'Grid',
    justifyContent: 'center',
    '& p': {
      color: '#121722',
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 350,
      lineHeight: '24px',
      letterSpacing: '0.15px'
  },
}
}));

export default styles;