import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    alignItems:'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundImage: "url('/assets/img/Elipses3.svg')",
    backgroundRepeat:'no-repeat',
     backgroundSize:'85% 85%',
    backgroundPositionX:'-240%',
    backgroundPositionY:'104%',
    maxWidth: 'max-content',
  },
  paper: {
    height: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: theme.spacing(5),
    margin:'2%',
    fontSize: 48,
    alignItems:'center !important',
    display:'grid !important',
    justifyContent:'center !important',
    [theme.breakpoints.only('xs')]: {
      fontSize: 40,
      justifyContent:'center !important',
      alignItems:'center !important'
    }
  },
  subtitle: {
    marginTop: theme.spacing(3),
    margin:'2%',
    fontSize: 18,
    alignItems:'center',
    display:'grid !important',
    justifyContent:'center !important',
    '& p': {
      color: '#FEFEFE',
    },
  },
  typographySubtitle:{
    color: 'var(--Blue-Gray-900, #0F172A)',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    marginTop:'25px',
    marginBottom:'50px',
  },
  typographyTitle:{
    color: 'var(--Blue-Gray-900, #0F172A)',
    textAlign: 'center',
    fontSize: '56px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '110%',
  },
  typographyContain:{
    color: '#121722',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '36px', /* 150% */
    letterSpacing: '0.225px',
    textAlign: 'left',
    height: '108px',
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      textAlign: 'center',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      textAlign: 'center',
    },
  },
  typographyContainLeft:{
    textAlign: 'left',
  },
  typographyMarginTop:{
    marginTop:'10%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  containCenterMargin:{
    margin:'5%',
    marginBottom: '9%',
    marginLeft: '3%',
    marginRight: '3%',
    [theme.breakpoints.only('xs')]: {
      marginTop: '5%',
      marginBottom: '30%',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '5%',
      marginBottom: '10%',
    },

  },
  textBeautifier:{
    width: '65% !important',
    display: 'inline-table',
    textAlign: 'center',
  },
  displayRuby:{
    display:'ruby',
  },
  image:{
    borderRadius:'16px',
    [theme.breakpoints.only('xs')]: {
      height:'200px',
      width:'250px',
      margin:'5%',
      marginBottom:'6%',
      alignItems:'center',
    },
    [theme.breakpoints.only('sm')]: {
      height:'200px',
      width:'400px',
      margin:'2%',
      marginBottom:'6%',
      alignItems:'center',
    },
    [theme.breakpoints.only('md')]: {
      height:'200px',
      width:'400px',
      margin:'4%',
      marginBottom:'6%',
      alignItems:'center',
    },
    [theme.breakpoints.only('lg')]: {
      height:'250px',
      width:'450px',
      margin:'0%',
      marginBottom:'6%',
      alignItems:'center',
    },

  },
  gridGreen:{
    marginTop: theme.spacing(2),
    color:'green',
    backgroundColor:'#e1fef0',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:'10px',
    margin:'4%',
  },
  gridPurple:{
    marginTop: theme.spacing(2),
    color:'purple',
    backgroundColor:'#e9d5ff',
    alignItems:'center',
    borderRadius:'10px',
    justifyContent:'center',
    margin:'4%',
  }
}));

export default styles;
