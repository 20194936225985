import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
    width:'100%',
    maxWidth:'none',
    '& p': {
      width:'100% !important',
    },
  },
  griplist:{
    [theme.breakpoints.only('xs')]: {
    margin:5
    }
  },
  paper: {
    backgroundColor: '#4058FF',
    minHeight: 680,
    marginTop: theme.spacing(10),
    padding: theme.spacing(10, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
  },
  title: {
    fontSize: 26,
  },
  subtitle: {
    fontSize: 22,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  typographySubtitle:{
    /* Body/M */
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  typographyTitle:{
    /* Heading/6 */
    fontFamily: 'Roboto',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '110%',
    marginBottom:16,
  },
  maskContainer: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
  },
  mask: {
    width: '100%',
    zIndex: 10,
    borderRadius: 11,
  },
  scrollContainer: {
    zIndex: 2,
    overflow: 'hidden',
    maxHeight: 400,
    width: '95%',
    position: 'absolute',
    top: 50,
    left: 20,
    willChange: 'transform',
    transformStyle: 'preserve-3d',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      left: 10,
      top: 60,
      maxHeight: 300,
    },
    [theme.breakpoints.only('sm')]: {
      left: 17,
      maxHeight: 300,
    },
    [theme.breakpoints.only('xs')]: {
      top: 30,
      left: 6,
      overflow: 'hidden',
      maxHeight: 140,
    },
  },
  content1: {
    width: '100%',
  },
  paperContainer: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
}));

export default styles;