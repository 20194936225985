import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    width:'100%',
    maxWidth: 'none',
    position: 'sticky',
    top: 0,
    backgroundColor: 'hsla(0,0%,100%,.95)',
    backdropFilter: 'blur(8px)',
    zIndex: 1300,
    paddingTop:5,
  },
  'MuiList-root':{
    top:40,
  },
  menuDown:{
    paddingTop:10,
    top:40,
  },
  nav: {
    listStyle: 'none',
    display: 'flex',
    '& li': {
      margin: '0 15px',
      alignSelf: 'center',
      '& a': {
        textDecoration: 'none',
        color: '#121722',
        fontSize: 16,
        '&:hover': {
          '& p': {
            color: '#4058FF',
          },
        },
      },
    },
  },
  li: {
    color: 'var(--Blue-Gray-900, #0F172A)',    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  typographySubtitle:{
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform:'none',
  },
  typographyTitle:{
    color: '#121722',
    textAlign: 'center',
    fontSize: '50px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '110%',
    textTransform:'none',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      zIndex: 1301,
      minWidth: 0,
      width: 35,
      height: 35,
      marginLeft: 10,
      textTransform:'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        display: 'none',
      },
    },
  },
  logo: {
    maxWidth: 142,
    height: 41.464,
  },
  logo2: {
    maxWidth: 24,
    height: '1.5em',
    marginLeft:6,
    marginRight:12
  },
  logo3:{
    maxWidth:12,
    height: 6,
    marginLeft:6,
    marginRight:12
  },
  btn: {
    margin: '0 8px !important',
    borderRadius: '42px !important',
    fontSize: 16,
  },
  btnLogin: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& p': {
      color: '#7C39ED',
      fontSize: 16,
    },
  },
  btnContainer: {
    textAlign: 'right',
    minWidth:580,
    justifyContent:'end',
    [theme.breakpoints.down('xs')]: {
      alignItems:"right",
      justifyContent:"center"
    }
  },
  btnStart: {
    backgroundColor: '#7C39ED !important',
    boxShadow: 'none !important',
    [theme.breakpoints.down('xs')]: {
      width:'90%',
    },
    '& p': {
      color: '#FEFEFE',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      }
    },
    '&.scroll': {
      backgroundColor: '#7C39ED !important',
      '& p': {
        color: '#FFF',
      }
    }
  },
  paper: {
    top: 50,
    width: '100%',
    boxShadow: 'none',
    padding: theme.spacing(0, 5),
  },
  selectorReact:{
    width:"25%",
    display:"flex",
    [theme.breakpoints.down('xs')]: {
      width:"40%"
    }
  },
  drawer: {
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'none',
    },
  },
  shortList:{
    [theme.breakpoints.down('xs')]: {
      display:'contents',
    }
  },
  navMovil: {
    listStyle: 'none',
    borderBottom: '1px solid rgba(18,23,34,.15)',
    '&.noBorder': {
      borderBottom: 'none !important',
    },
    '& li': {
      margin: '25px 0px',
      alignSelf: 'center',
      '& a': {
        textDecoration: 'none',
      },
    },
  },
}));

export default styles;