import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
    width:'100%',
    maxWidth:'none',
  },
  rootspacing:{
    marginTop: theme.spacing(5),
    textAlign: 'center',
    width:'100%',
    [theme.breakpoints.only('lg')]: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    },
    maxWidth:'none',
  },
  typographySubtitle:{
    /* Body/M */
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    textAlign:'center',
    letterSpacing:'0.2px',
  },
  typographyTitle:{
    /* Heading/6 */
    color: 'var(--Blue-Gray-900, #0F172A)',
    fontSize: 56,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '110%',
    marginBottom:16,
    textAlign:'center',
  },
  griplist:{
    backgroundColor: 'white',
    border:'1px solid #4363',
    borderRadius: 6,
    marginTop:'5%',
    margin:'3%',
    position: 'relative',
    zIndex: '1',
    boxShadow: '0px 0px 10px 20px #ede9f4',
    [theme.breakpoints.only('xs')]: {
    margin:5
    }
  },
  paper: {
    backgroundColor: '#4058FF',
    minHeight: 680,
    marginTop: theme.spacing(10),
    padding: theme.spacing(10, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
  },
  textMiddle:{
    justifyContent:'center !important',
    display:'flex !important',
  },
  title: {
    fontSize: 34,
  },
  subtitle: {
    fontSize: 20,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  imageCircle:{
    height:'150px',
    width:'150px',
    borderRadius: 120,
    borderColor:'white',
    borderStyle:'solid',
    borderWidth:'thick',
    position:'relative',  
    boxShadow: '0px 0px 10px 10px #ede9f4',
    marginTop:'-22%',
    zIndex: '1',
  },
  maskContainer: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
  },
  mask: {
    width: '100%',
    zIndex: 10,
    borderRadius: 11,
  },
  scrollContainer: {
    zIndex: 2,
    overflow: 'hidden',
    maxHeight: 400,
    width: '95%',
    position: 'absolute',
    top: 50,
    left: 20,
    willChange: 'transform',
    transformStyle: 'preserve-3d',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      left: 10,
      top: 60,
      maxHeight: 300,
    },
    [theme.breakpoints.only('sm')]: {
      left: 17,
      maxHeight: 300,
    },
    [theme.breakpoints.only('xs')]: {
      top: 30,
      left: 6,
      overflow: 'hidden',
      maxHeight: 140,
    },
  },
  content1: {
    width: '100%',
  },
  content2: {
    width: '90% !important',
  },
  cards:{
    width:'30%',
  },
  paperContainer: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: 150,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 70,
      }
    }
  },
}));

export default styles;