import React, { Suspense } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Switch, Route, BrowserRouter as HashRouter } from 'react-router-dom';
import Drift from 'react-driftjs';
import ThemeDefault from './themes/default';
import Index from './pages/index';
import Privacy from './pages/privacy';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Suspense fallback={<span></span>}>
      <ThemeProvider theme={ThemeDefault}>
        <CssBaseline />
        <div className="App">
          <Drift appId="hrtfz4fw3fuk" />
          <HashRouter>
            <Switch>
              <Route path="/" component={Index} exact={true} />
              <Route path="/privacy" component={Privacy} exact={true} />
              <Route path="*" component={NotFound} exact={true} />
            </Switch>
          </HashRouter>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
