import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  root: {
    width:'313.33px',
    height:'487.02px',
    marginLeft:45,
    marginRight:45,
    [theme.breakpoints.only('xs')]:{
      marginLeft:'5%',
      marginRight:0,
    },
    marginBottom:"5%",
    alignItems:'center',
    borderRadius: 16,
    background: 'linear-gradient(58deg, #5B21B6 0%, #AB40FF 100%)',
    '&:hover': {
      cursor: 'pointer',
      '& img': {
        transform: 'scale(1.1)',
      },
      '& .name': {
        color: '#4058FF',
        opacity: 0.8,
      },
    },
  },
  btnStart: {
    backgroundColor: 'linear-gradient(58deg, #5B21B6 0%, #AB40FF 100%)',
    marginTop:"25px",
    marginBottom:"10px",
    width:"100%",
    height:36,
    borderStyle:"solid",
    borderRadius: 60,
    borderColor:"#FEFEFE",
    color:"white",
    textTransform:'none',
    borderBlockStartWidth:"1px",
    borderWidth:"1px",
    boxShadow: 'none !important',
    '& p': {
      color: '#FEFEFE',
      fontSize:16,
    },
    '&.scroll': {
      backgroundColor: '#7C39ED !important',
      '& p': {
        color: '#FEFEFE',

      }
    }
  },
  img: {
    borderRadius: 16,
    marginLeft:15,
    width:'40px',
    height:'40px',
    marginBottom: -20,
    marginTop: 30,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.sta
    }),
  },
  imgLogo: {
    borderRadius: 16,
    height:18,
    width:18,
    marginLeft:5,
    marginRight:5,
  },
  name: {
    opacity: 0.45,
    fontSize: 13,
  },
  description: {
    fontSize: 20,
    height: 30,
    width:"100%"
  },
  color:{
  color:"#FFF"
},
fontsizeBig:{
  color: '#FFF',
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 350,
  lineHeight: '48px',
  letterSpacing: '0.3px',
},
fontsizeMedium:{
  color: '#FFF',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '27px',
  letterSpacing: '0.169px',
},
fontsizeSmall:{
  color: '#FFF',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 350,
  lineHeight: '25.5px', 
  letterSpacing: '0.159px',
},
fontsizeXSmall:{
  color: '#FFF',
fontSize: '13px',
fontStyle: 'normal',
fontWeight: 400,
lineHeight: '19.5px',
letterSpacing: '0.122px',
},
feature:{
  width:"100%"
},
leftItems:{
  display:"grid",
  justifyContent:"start",
  marginLeft:"5%",
  alignItems: 'center',
},
centerObject:{
  display:"grid",
  justifyContent:"center",
  marginTop:'20%',
},
heightNormal:{
  height:'120px',
},
initCard:{
  width:'313.33px',
  height:'487.02px',
}
}));

export default styles;
