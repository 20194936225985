import React from 'react';
import Nav from '../components/Nav';
import Header from '../sections/Header';
import Why from '../sections/Why';
import Why3 from '../sections/Why3';
import Gallery from '../sections/Gallery';
import Footer from '../sections/Footer';
import Why4 from '../sections/Why4';
import Users from '../sections/Users';
import List from '../sections/List';
import Prices from '../sections/Prices';

const Index = () => (
  <main>
    <Nav />
    <Header />
    <Why />
    <List />
    <Why3 />
    <Why4 />
    <Users />
    <Gallery />
    <Prices/>
    <Footer />
  </main>
);

export default Index;

