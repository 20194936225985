import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    borderBottom: '1px solid var(--Blue-Gray-200, #E2E8F0)',
    textAlign: 'center',
    backgroundImage: "url('/assets/img/ElipsesVideo.svg')",
    backgroundRepeat:'no-repeat',
     backgroundSize:'100% 120%',
    backgroundPositionX:'50%',
    backgroundPositionY:'-10%',
    maxWidth:'none',
    [theme.breakpoints.only('xs')]: {
      backgroundSize:'110% 110%',
      backgroundPositionX:'45%',
      backgroundPositionY:'-150%',
      marginTop:'20%',
    },
    '& p': {
    },
  },
  heightBody1:{
    height: '470px',
  },
  paper: {
    backgroundColor: '#4058FF',
    minHeight: 680,
    marginTop: theme.spacing(10),
    padding: theme.spacing(10, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
  },
  title2: {
    fontSize: 34,
    color: '#FFF',
  },
  subtitle2: {
    fontSize: 20,
    color: '#FFF',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #4b93ff',
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  typographySubtitle:{
    color: 'var(--Blue-Gray-900, #0F172A)',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.2px',
    marginTop:'3%',
    marginBottom:'5%',
  },
  typographyTitle:{
    color: 'var(--Blue-Gray-900, #0F172A)',
    textAlign: 'center',
    fontSize: '50px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    [theme.breakpoints.only('xs')]: {
    },
  },
  textBeautifier:{
    width:'69% !important',
    [theme.breakpoints.only('xs')]: {
      width:'100% !important',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100% !important',
    },
    textAlign:'center',
    display:'inline-table',
  },
  maskContainer: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
  },
  mask: {
    width: '100%',
    zIndex: 10,
    borderRadius: 11,
  },
  scrollContainer: {
    zIndex: 2,
    overflow: 'hidden',
    maxHeight: 400,
    width: '95%',
    position: 'absolute',
    top: 50,
    left: 20,
    willChange: 'transform',
    transformStyle: 'preserve-3d',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      left: 10,
      top: 60,
      maxHeight: 300,
    },
    [theme.breakpoints.only('sm')]: {
      left: 17,
      maxHeight: 300,
    },
    [theme.breakpoints.only('xs')]: {
      top: 30,
      left: 6,
      overflow: 'hidden',
      maxHeight: 140,
    },
  },
  content1: {
    width: '100%',
  },
  paperContainer: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  video: {
    width: '85%',
    height: 500,
    borderRadius: '17.565px',
    marginBottom:'5%',
    border: '7.026px solid #4C1D95',
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      height: 'auto',
    }
  }
}));

export default styles;
