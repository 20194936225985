/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Grid } from '@material-ui/core'; //Paper
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '../../components/Typography';
import styles from './styles';
import PriceCards from '../../components/PriceCards';

const List = () => {
  const { t } = useTranslation();
  const classes = styles();

  return (

    <Container className={classes.root} id="pricing">

      <Grid container>
        <Grid item lg={12} xs={12} md={12}>
          <Typography className={classes.typographyTitle}>{t('prices.title')}</Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12}>
          <Typography className={clsx(classes.typographySubtitle,classes.marginTypographyMin)}>{t('prices.subtitle')}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.rootspacing} item md={12} lg={12}>
        <Grid item md={4} lg={4} xs={12}>
          <PriceCards
            freepay={true}
            isFree={t('prices.priceFree')}
            title={t('prices.titleFree')}
            description={t("prices.freeFeatures")}
            src="/assets/img/select1.png"
          />
        </Grid>
        <Grid item md={4} lg={4} xs={12}>
          <PriceCards
            freepay={false}
            isFree={t('prices.priceMonthly')}
            title={t('prices.titleMonthly')}
            description={t('prices.proFeatures')}
            src="/assets/img/select1.png"
          />
        </Grid>
        <Grid item md={3} lg={3} xs={12}>
          <PriceCards
            freepay={false}
            isFree={t('prices.priceAnnual')}
            title={t('prices.titleAnnual')}
            description={t('prices.proFeatures')}
            src="/assets/img/select1.png"
          />
        </Grid>
        <Grid />
      </Grid>
    </Container>
  );
};

export default List;
