import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import styles from './styles';

const Privacy = () => {
  const { t } = useTranslation();
  const classes = styles();

  const getTxt = (txt = '') => {
    const arr = txt.split('/n/');

    return arr.map((text) => <Typography className={classes.description}>{text}</Typography>);
  }

  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography bold className={classes.h2}>{t('privacy.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.collection.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.collection.description'))}
        </Grid>
        <Grid item xs={12}>
          <nav className={classes.nav}>
            {t('privacy.collection.third', { returnObjects: true }).map((txt, i) => (
              <li key={i}><Typography component="span">{txt}</Typography></li>
            ))}
          </nav>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.collection.include'))}
        </Grid>
        <Grid item xs={12}>
          <nav className={classes.nav}>
            {t('privacy.collection.include_elements', { returnObjects: true }).map((txt, i) => (
              <li key={i}><Typography component="span">{txt}</Typography></li>
            ))}
          </nav>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.collection.collect'))}
        </Grid>
        <Grid item xs={12}>
          <nav className={classes.nav}>
            {t('privacy.collection.collect_elements', { returnObjects: true }).map((txt, i) => (
              <li key={i}><Typography component="span">{txt}</Typography></li>
            ))}
          </nav>
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.log')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.log_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.cookies')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.cookies_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.providers.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.providers.description'))}
        </Grid>
        <Grid item xs={12}>
          <nav className={classes.nav}>
            {t('privacy.providers.elements', { returnObjects: true }).map((txt, i) => (
              <li key={i}><Typography component="span">{txt}</Typography></li>
            ))}
          </nav>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.providers.want'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.security')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.security_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.links')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.links_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.childrens')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.privacy_children_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.changes')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.changes_description'))}
        </Grid>
        <Grid item xs={12}>
          <Typography bold className={classes.title}>{t('privacy.contact')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTxt(t('privacy.contact_description'))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Privacy;
