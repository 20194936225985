import React from 'react';
import Nav from '../components/Nav';
import Privacy from '../sections/Privacy';
import Footer from '../sections/Footer';

const Index = () => (
  <main>
    <Nav />
    <Privacy />
    <Footer />
  </main>
);

export default Index;

