import React from 'react';
import propTypes from 'prop-types';
import { Grid, Paper, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import styles from './styles';

const PriceCard = ({ freepay,isFree,title,description,src }) => {
  const { t } = useTranslation();
  const classes = styles();

  const featureList = description.split(',').map(description => description.trim());

  const open = () => window.open('https://app.getbiew.com/', '_blank');

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.initCard}>
        <Grid item>
          <img
            alt="img"
            src={src}
            className={classes.img} />
        </Grid>
        <Typography className={clsx(classes.color, classes.feature,classes.fontsizeXSmall ,classes.leftItems)}>{freepay ? ('FREE') : ('PRO')}</Typography>
        <Grid item xs={12}>
          <Typography display="flex" className={clsx(classes.description,classes.leftItems, classes.color, classes.fontsizeBig)}>
          {isFree}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(classes.color, classes.leftItems,  classes.price,classes.fontsizeMedium)}>
          {title}
          </Typography>
        </Grid>
        <Grid item xs={12} className={clsx(classes.leftItems,classes.heightNormal)}> 
        {Array.isArray(featureList) ? (
          featureList.map((feature, index) => (
              <Typography
                bold
                className={clsx(classes.color, classes.feature,classes.fontsizeSmall)}
                key={index}
              >

              <img
            alt="img"
            src="../assets/img/check-bold.png"
            height="15px"
            width="15px"
            className={classes.imgLogo} />
                {feature}
              </Typography>
            ))
          ) : (
            
            <Typography className={clsx(classes.color, classes.price)}>
            <img
            alt="img"
            src="../assets/img/check-bold.png"
            height="18px"
            width="18px"
            className={classes.imgLogo} />
             {description}</Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.centerObject}>
          <Button variant="text" className={classes.btnStart} onClick={open}>
            {t('price.start')} →
          </Button>
        </Grid>
        
      </Grid>
    </Paper> 
  );
};

PriceCard.propTypes = {
  /** freepay */
  freepay:propTypes.bool,
  /** isFree */
  isFree: propTypes.string.isRequired,
  /** Subtitle */
  title: propTypes.string.isRequired,
  /** description */
  description: propTypes.any,
  /** Define if is white */
  src: propTypes.string,
};

PriceCard.defaultProps = {
  inside: false,
  white: false,
  className: '',
};



export default PriceCard;
