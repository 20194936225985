/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '../../components/Typography'
import styles from './styles';

const Footer = () => {
  const { t } = useTranslation();
  const classes = styles();

  const chat = () => {
    window.drift.api.sidebar.open();
  }

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item lg={3} md={3} sm={6} xs={12} className={classes.container}>
          <div>
            <img alt="logo" src="/assets/img/logo.png" className={classes.logo} />
            <Typography className={classes.copyright}>
              Copyright © 2024 Biew <br />
              {t('footer.reserved')}
            </Typography>
          </div>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12} className={classes.container}>
          <nav className={classes.nav}>
            <li><Typography>Legal</Typography></li>
            <li><Link to="/privacy"><Typography>{t('footer.policy')}</Typography></Link></li>
          </nav>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12} className={classes.container}>
          <nav className={classes.nav}>
            <li><Typography>{t('footer.support')}</Typography></li>
            <li><a href="javascript:void(0)" onClick={chat}><Typography>{t('footer.chat')}</Typography></a></li>
            <li><a href="mailto:hi@getbiew.com"><Typography>Hi@getbiew.com</Typography></a></li>
          </nav>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12} className={classes.container}>
          <nav className={classes.nav}>
            <li><Typography>General</Typography></li>
            <li><a href="https://www.instagram.com/getbiew/" target="_blank" rel="noreferrer">
              <Typography>Instagram</Typography>
            </a></li>
          </nav>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
