import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '../../components/Typography';
import GalleryItem from '../../components/GalleryItem';
import styles from './styles';

const Gallery = () => {
  const { t } = useTranslation();
  const classes = styles();

  return (
    <Container className={classes.root} id="gallery">
      {/* <Title
        title={t('gallery.title')}
        subtitle={t('gallery.subtitle')}
        className={clsx(classes.title,classes.center)}
      />*/}
      <Grid item xs={12}>
      <Typography bold className={classes.typographyTitle}>{t('gallery.title')}</Typography>
      <Typography className={clsx(classes.marginTextSubtitle,classes.typographySubtitle)}>{t('gallery.subtitle')}</Typography>
      </Grid>


       
      <Grid container spacing={4} style={{ marginTop: 20 }} className={clsx(classes.title)}>
        {t('gallery.items', { returnObjects: true }).map((e, i) => (
          <Grid item key={i} lg={4} md={4} sm={6} xs={12}>
            <GalleryItem item={e}/>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Gallery;
