import React from 'react';
import propTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles';

const UserFeature = ({ backgroundColor, title, description, src }) => {
  const classes = styles();

  return (
    <Paper
      className={classes.paper}
    >
      <Grid container className={classes.containerSpace} >
        <Grid container className={classes.griplist}>
          <div className={classes.backgroundOverview} />
          <div className={classes.overview} />
          <img
            alt="img"
            src={src}
            className={classes.imageCircle}
          />
          <Typography className={classes.typographySubtitle}>{title}</Typography>
          <Typography className={classes.typographyTitle}>{description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

UserFeature.propTypes = {
  /** Background color  */
  backgroundColor: propTypes.string,
  /** Bold title */
  title: propTypes.string.isRequired,
  /** description */
  description: propTypes.string.isRequired,
  /** Image url */
  src: propTypes.string.isRequired,
};

UserFeature.defaultProps = {
  backgroundColor: '#FFF',
};

export default UserFeature;
