/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Grid } from '@material-ui/core'; //Paper
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import styles from './styles';
import UserFeature from '../../components/UserFeature';
import clsx from 'clsx';


const List = () => {
  const { t } = useTranslation();
  const classes = styles();
  const items = [
    {
      title: t('users.client1.description'),
      description: t('users.client1.name'),
      src: '/assets/img/userCliente1.png',
    },
    {
      title: t('users.client2.description'),
      description: t('users.client2.name'),
      src: '/assets/img/userCliente2.png',
    },
    {
      title: t('users.client3.description'),
      description: t('users.client3.name'),
      src: '/assets/img/userCliente3.png',
    },
  ];

  return (

    <Container className={classes.root} id="list">
      <Grid container>
        <Grid item xs={12} lg={12}>
        <div className={classes.textMiddle}>
        <Typography className={clsx(classes.typographyTitle,classes.content2)}>{t('users.title')}</Typography>
        </div>
          <div className={classes.textMiddle}>
          <Typography className={clsx(classes.typographySubtitle,classes.content2)}>{t('users.subtitle')}</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.rootspacing}>
            {items.map((item, index) => (
              <Grid item lg={4} md={4} sm={12} xs={12} key={index} className={classes.item}>
                <UserFeature
                  title={item.title}
                  description={item.description}
                  src={item.src}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
