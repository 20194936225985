import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    alignItems:'center',
  },
  rootspacing:{
    marginTop: theme.spacing(4),
    width:"91%",
    maxWidth:'none',
    justifyContent:'center',
    [theme.breakpoints.only('lg')]: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    },
    '& p': {
      width:'100% !important',
    },
  },
  paper: {
    height: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typographySubtitle:{
    color: '#0F172A',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.2px',
  },
  typographyTitle:{
    marginTop:theme.spacing(8),
    color: '#121722',
    textAlign: 'center',
    fontSize: '56px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '54px',
  },
  marginTypographyMin:{
    marginTop: '2%',
    marginBottom:'1%',
  },
  title: {
    marginTop: theme.spacing(3),
    margin:'2%',
    fontSize: 56,
    alignItems:'center !important',
    display:'grid !important',
    justifyContent:'center !important',
    [theme.breakpoints.only('xs')]: {
      fontSize: 56,
      justifyContent:'center !important',
      alignItems:'center !important'
    }
  },
  subtitle: {
    marginTop: -15,
    margin:'2%',
    fontSize: 18,
    alignItems:'center !important',
    display:'grid !important',
    justifyContent:'center !important',
    '& p': {
      color: '#FEFEFE',
    },
  },
  image:{
    [theme.breakpoints.only('xs')]: {
      height:'150px',
      width:'200px',
      margin:'2%',
      alignItems:'center'
    },
    [theme.breakpoints.only('sm')]: {
      height:'150px',
      width:'200px',
      margin:'2%',
      alignItems:'center'
    },
    [theme.breakpoints.only('md')]: {
      height:'200px',
      width:'400px',
      margin:'4%',
      alignItems:'center'
    },
    [theme.breakpoints.only('lg')]: {
      height:'200px',
      width:'400px',
      margin:'4%',
      alignItems:'center'
    },

  },
  gridGreen:{
    marginTop: theme.spacing(2),
    color:'green',
    backgroundColor:'#e1fef0',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:'10px'
    
  },
  gridPurple:{
    marginTop: theme.spacing(2),
    color:'purple',
    backgroundColor:'#e9d5ff',
    alignItems:'center',
    borderRadius:'10px',
    justifyContent:'center'
  }
}));

export default styles;
