import React from 'react';
import { Container, Grid } from '@material-ui/core'; //Button
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '../../components/Typography';
import styles from './styles';

const Why4 = () => {
  const { t } = useTranslation();
  const classes = styles();


  return (
    <Container className={classes.root}>
      <Grid container item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.displayRuby}>
          <Typography className={clsx(classes.typographyTitle,classes.textBeautifier)}>{t('why4.title')}</Typography>
          <Typography className={classes.typographySubtitle}>{t('why4.subtitle')}</Typography>
        </Grid>

        <Grid container className={classes.gridPurple} item xs={12} sm={12} md={5} lg={5}>
          <Typography className={clsx(classes.typographyContainLeft,classes.typographyContain,classes.containCenterMargin)}>{t('why4.description1')}</Typography>
          <img
            alt="img"
            src="/assets/img/Why4Excel.png"
            className={classes.image}
          />
        </Grid>
        
        <Grid container className={classes.gridGreen} item xs={12} sm={12} md={5} lg={5}>
          <br></br>
          <Typography className={clsx(classes.typographyMarginTop,classes.typographyContain)}>{t('why4.description2')}</Typography>
          <img
            alt="img"
            src="/assets/img/why4Create.png"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Why4;