import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    maxWidth: 'max-content',
    marginTop: theme.spacing(9),
    padding: theme.spacing(0, 10),
    backgroundImage: "url('/assets/img/Elipses4.svg')",
    backgroundRepeat:'no-repeat',
     backgroundSize:'85% 85%',
    backgroundPositionX:'140%',
    backgroundPositionY:'120%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5),

    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 5),
      backgroundSize:'98% 98%',
    backgroundPositionX:'140%',
    backgroundPositionY:'2100%',
    },
  },
  typographySubtitle:{
    color: '#121722',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  },
  typographyTitle:{
    color: '#121722',
    textAlign: 'center',
    fontSize: 56,
    fontStyle: 'normal',
    fontWeight: 600,
    marginBottom:10,
  },
  marginTextSubtitle:{
    marginBottom: '4%',
  },
  title: {
    justifyContent:'center',
    '& .subtitle': {
    justifyContent:'center',
    },
  },
  center:{
    justifyContent:'center',
    display:'grid',
  }
}));

export default styles;
