/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core'; //
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '../../components/Typography';
import styles from './styles';


const Why = () => {
  const { t } = useTranslation();
  const classes = styles();

  const handleScroll = (e) => {
    const element = document.getElementById('why');
    if (!element) return;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <Container className={classes.root} id="why">
      {/* <Title
        title={t('why.title')}
        subtitle={t('why.subtitle')}
      /> */}
      <Grid item xs={12}>
          <Typography className={classes.typographyTitle}>{t('why.title')}</Typography>
          <Typography className={clsx(classes.typographySubtitle,classes.textBeautifier)}>{t('why.subtitle')}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <iframe
            src="https://www.youtube.com/embed/IdZB2yBR46A"
            title="Introduction to Biew - Create websites with Google Sheets" 
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className={classes.video}
          >
          </iframe>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Why;
