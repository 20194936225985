import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../Typography';
import styles from './styles';

const GalleryItem = ({ item }) => {
  const classes = styles();

  return (
    <a href={item.url} className={classes.link} target="_blank" rel="noreferrer">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <img
            alt="img"
            src={item.img}
            className={classes.img}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(classes.name, 'name')}>{item.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.description}>
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    </a>
  );
}

export default GalleryItem;
