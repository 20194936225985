import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  '@global': {
    '@keyframes MoveUpDown': {
      '0%, 100%': {
        bottom: 0,
      },
      '50%': {
        bottom: -30,
      },
    },
  },
  root: {
    borderTop: '1px solid var(--Blue-Gray-200, #E2E8F0)',
    borderBottom: '1px solid var(--Blue-Gray-200, #E2E8F0)',
    background: '#FFF',
    marginTop: theme.spacing(3),
    marginRight:'3%',
    maxWidth:'none',
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 5),
      marginTop: theme.spacing(2),
      textAlign: 'center'
    },
  },
  heightBody1:{
    height: '470px',
    [theme.breakpoints.only('xs')]: {
      height: '130%',
    },
    [theme.breakpoints.only('sm')]: {
      height: '130%',
    },
  },
  container: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  centerdiv: {
    display:'grid !important',
    justifyContent:'center !important',
  },
  title: {
    fontSize: 49,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: 40,
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: 20,
    color: '#121722',
    textAlign: 'center',
  },
  marginContainerTop:{
    marginTop: '2%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0%',
    },
    
  },
  typographySubtitle:{
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform:'none',
  },
  typographyTitle:{
    color: '#121722',
    textAlign: 'center',
    fontSize: '50px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '110%',
  },
  spaceVerticalMiddle:{
    marginTop:30,
    marginBottom:30,
  },
  widthSmall:{
    width:360,
  },
  video: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  image: {
    width: '90%',
    animation: " MoveUpDown 3s linear infinite",
  },
  btnStart: {
    borderRadius: 42,
    height: 48,
    width: 180,
    marginTop: '20px',
    textAlign: 'center',
    background: 'linear-gradient(180deg,#7c39ed,#894af7)',
    '& p': {
      color: '#FFF'
    },
  },
}));

export default styles;
