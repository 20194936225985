/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Container, Button, Drawer, Menu, MenuItem, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Typography from '../Typography';
import styles from './styles';

const Nav = () => {
  const { hash } = useLocation();
  const classes = styles();
  const { t, i18n } = useTranslation();
  const [menuMovil, setmenuMovil] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openLenguageMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl((current) => current ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changeLanguage = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    setAnchorEl(null);
  };

  const handleMenuMovil = () => setmenuMovil((current) => !current);

  const handleScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > 350) {
      let diff = (winScroll - 450) / 10000;
      if (diff > 0.05) diff = 0.05;
    };

    if (winScroll > 350)
      document.getElementById('btnStart').classList.add('scroll');
    else
      document.getElementById('btnStart').classList.remove('scroll');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setmenuMovil(false);
    if (hash !== '') {
      const id = hash.replace('#', '');
      setTimeout(() => document.getElementById(id).scrollIntoView(true), 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash]);

  const open = () => window.open('https://app.getbiew.com/', '_blank');

  return (
    <Container className={classes.root} id="nav">
      <Drawer
        open={menuMovil}
        anchor="bottom"
        hideBackdrop
        keepMounted={false}
        className={classes.drawer}
        classes={{ paper: clsx(classes.paper) }}>
        <nav className={classes.navMovil}>
          <li><a href="#why"><Typography className={classes.typographySubtitle}>{t('nav.why')}</Typography></a></li>
          <li><a href="#features"><Typography className={classes.typographySubtitle}>{t('nav.features')}</Typography></a></li>
          <li><a href="#pricing"><Typography className={classes.typographySubtitle}>{t('nav.prices')}</Typography></a></li>
          <li><a href="#gallery"><Typography className={classes.typographySubtitle}>{t('nav.gallery')}</Typography></a></li>
        </nav>
        <nav className={clsx(classes.navMovil, 'noBorder')}>
          <li><a href="#" onClick={open}><Typography>Login</Typography></a></li>
        </nav>
      </Drawer>
      <Box sx={{ display: 'flex', width: '100hv' }}>
        <Box flex={1} sx={{}}>
          <nav className={classes.nav}>
            <li className={classes.imgContainer}>
              <img
                alt="logo"
                src="/assets/img/logo.png"
                className={classes.logo}
              />
              <Button size="small" onClick={handleMenuMovil}>
                <Menu />
              </Button>
            </li>
            <li className={classes.li}>
              <Link to={{
                pathname: '/',
                hash: '#why'
              }}>
                <Typography className={classes.typographySubtitle}>{t('nav.why')}</Typography>
              </Link>
            </li>
            <li className={classes.li}>
              <Link to={{
                pathname: '/',
                hash: '#features'
              }}>
                <Typography className={classes.typographySubtitle} href="#Features">{t('nav.features')}</Typography>
              </Link>
            </li>
            <li className={classes.li}>
              <Link to={{
                pathname: '/',
                hash: '#pricing'
              }}>
                <Typography className={classes.typographySubtitle} href="#pricing">{t('nav.pricing')}</Typography>
              </Link>
            </li>
            <li className={classes.li}><a href="#gallery"><Typography className={classes.typographySubtitle}>{t('nav.gallery')}</Typography></a></li>
          </nav>
        </Box>
        <Box sx={{ display: 'contents', width: '100hv' }}>
          <Button
            id="basic-button"
            aria-controls={openLenguageMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openLenguageMenu ? 'true' : undefined}
            onClick={handleClick}
          >
            <img
              alt="logo"
              src="/assets/img/iconoLanguage.svg"
              className={classes.logo2}
            />
            <Typography className={classes.typographySubtitle}>{i18n.language.split('-')[0].charAt(0).toUpperCase() + i18n.language.split('-')[0].slice(1)}</Typography>
            <img
              alt="logo"
              src="/assets/img/Vector.svg"
              className={classes.logo3}
            />

          </Button>

          <Menu
            className={classes.menuDown}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
              vertical: -40,
              horizontal: 0,
            }}
          >
            <MenuItem onClick={(selectedOption) => changeLanguage('De')}>Aleman</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('En')}>English</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Es')}>Español</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Pt')}>Português</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Ar')}>عرب</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Fr')}>Français</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Tr')}>Türkçe</MenuItem>
            <MenuItem onClick={(selectedOption) => changeLanguage('Fil')}>Tagalog</MenuItem>
          </Menu>
          <Button
            variant="text"
            className={clsx(classes.btn, classes.btnLogin)}
            onClick={open}>
            <Typography className={classes.typographySubtitle}>{t('nav.login')}</Typography>
          </Button>
          <Button
            variant="contained"
            id="btnStart"
            className={clsx(classes.btn, classes.btnStart)}
            onClick={open}>
            <Typography className={classes.typographySubtitle}>{t('nav.started')}</Typography>
          </Button>
        </Box>

      </Box>
    </Container>
  );
};

export default Nav;
