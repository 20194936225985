/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Grid } from '@material-ui/core'; //Paper
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import styles from './styles';


const List = () => {
  const { t } = useTranslation();
  const classes = styles();

  return (

    <Container className={classes.root} id="list">
      <Grid container>
        <Grid item xs={12} md={3} className={classes.griplist}>
          <img
            alt="img"
            src="/assets/img/photo.svg"
            className={classes.image}
            width="96" height="96"
          />
          <Typography bold className={classes.typographyTitle}>{t('list.card1.title')}</Typography>
          <Typography className={classes.typographySubtitle}>{t('list.card1.subtitle')}</Typography>

        </Grid>
        <Grid item xs={12} md={3} className={classes.griplist}>
          <img
            alt="img"
            src="/assets/img/disc.svg"
            className={classes.image}
            width="96" height="96"
          />
          <Typography bold className={classes.typographyTitle}>{t('list.card2.title')}</Typography>
          <Typography className={classes.typographySubtitle}>{t('list.card2.subtitle')}</Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.griplist}>
          <img
            alt="img"
            src="/assets/img/food.svg"
            className={classes.image}
            width="96" height="96"
          />
          <Typography bold className={classes.typographyTitle}>{t('list.card3.title')}</Typography>
          <Typography className={classes.typographySubtitle}>{t('list.card3.subtitle')}</Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.griplist}>
          <img
            alt="img"
            src="/assets/img/calendar.svg"
            className={classes.image}
            width="96" height="96"
          />
          <Typography bold className={classes.typographyTitle}>{t('list.card4.title')}</Typography>
          <Typography className={classes.typographySubtitle}>{t('list.card4.subtitle')}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
